/** Globale dependencies*/
@import ../app/assets/bower_components/bourbon/app/assets/stylesheets/bourbon
@import settings/neat
@import ../app/assets/bower_components/neat/app/assets/stylesheets/neat

/** Settings voor onze app (kleuren en layout) */
@import settings/colors
@import settings/variables

/** Overige print pagina's*/
@import print/artsenverklaring
@import print/artsenverklaring2020
@import print/artsenverklaring2022

@page
  margin: 1cm
  orphans: 5
  size: A4

*
  font-size: .95em

header, .title-bar, .logo, .organisatie-dropdown, .user-controls, #toolbar, .actions, button, footer
  display: none
  visibility: none
  height: 0em

.page
  +span-columns(12)
  position: relative
  height: 100%

.print-header
  padding: 0.3em
  position: relative
  +span-columns(12)

  page-break-inside: avoid !important
  break-inside: avoid !important

.ui-datepicker
  display: none

.ondertekening
  margin-top: 1em
  margin-bottom: 2em
  +span-columns(12)
  position: relative
  .naam, .handtekening
    height: 5em
    +span-columns(5.5)
    +shift(.25)
    position: relative
    border-bottom: 1px solid black

.printregel
  padding: 0.3em

  +span-columns(12)
  border: 1px solid $donkergrijs
  margin-bottom: 1em
  .printregel-header
    +span-columns(12)
    position: relative
    border-bottom: 1px solid $donkergrijs

    .printregel-info, .printregel-info-2
      > span
        display: block
        float: left
        width: 16.6%
        word-break: break-all

    table, tbody
      +span-columns(12)
      border-spacing: 0
      border-collapse: collapse

      tr
        width: 100%
        float: left
        display: block
        td
          width: 16.6%
          display: block
          float: left
          word-break: break-all

          &:last-child
            float: right

          &[colspan="2"]
            width: 33.2%

          &[colspan="3"]
            width: 49.8%

          &[colspan="4"]
            width: 66.4%

  .printregel-content
    +span-columns(12)
    padding-top: .5em
    position: relative
    table, tbody
      +span-columns(12)
      border-spacing: 0
      border-collapse: collapse
      tr
        width: 100%
        float: left
        display: block
        td
          width: 16.6%
          display: block
          float: left
          word-break: break-all

          &:last-child
            float: right

          &[colspan="2"]
            width: 33.2%

          &[colspan="3"]
            width: 49.8%

          &[colspan="4"]
            width: 66.4%

          &[colspan="5"]
            float: left
            width: 100%

    .geleverd-product
      border-top: 1px solid $donkergrijs
      padding-top: .5em

    .bijzonderheden
      +span-columns(12)
      border-top: 1px solid $donkergrijs
      padding-top: .5em

    .notities-seperator
      +span-columns(12)
      border-top: 1px solid $donkergrijs
      padding-top: .5em

.notities
  position: relative
  list-style: none
  padding-top: .5em
  left: 0em
  margin-left: -4em

  .notitie
    +span-columns(12)
    max-height: 30em
    font-size: 1.1em
    border-top: 1px solid lightgrey

    .sender-information
      +span-columns(2)
      .user
        line-height: 1.5em

      .date-time
        min-height: 2em

        .om
          font-style: italic
          font-size: .9em

    .bericht-container
      +span-columns(9)
      min-height: 4.9em
      border-left: 1px solid lightgrey

      .bericht
        padding-left: 1em
        padding-top: 0.5em
        min-height: 2.8em

      .gelezen
        padding-top: 0.5em
        padding-left: 1em
        padding-bottom: 0.5em